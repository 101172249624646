import React from 'react'
import useIsMobile from '@hooks/useIsMobile'
import Header from '@views/home/Header'
import Introduce from '@views/home/Introduce'
import Videos from '@views/home/Videos'
import Guest from '@views/home/Guest'
import Agenda from '@views/home/Agenda'
import Playback from '@views/home/Playback'
import Support from '@views/home/Support'
import Banner from '@views/home/Banner'
import Contactus from '@views/home/Contactus'
import Hotspot from '@views/home/Hotspot'
import './index.scss'

function Home () {
  const { isMobile } = useIsMobile()

  const children = [
    <Banner
        id="Banner"
        key="Banner"
        isMobile = { isMobile }
    />,
     <Header
        id="Header"
        key="Header"
        isMobile = { isMobile }
    />,
    <Introduce
        id="Introduce"
        key="Introduce"
        isMobile = { isMobile }
    />,
    <Videos
        id="Videos"
        key="Videos"
        isMobile = { isMobile }
    />,
    <Guest
        id="Guest"
        key="Guest"
        isMobile = { isMobile }
    />,
    <Agenda
        id="Agenda"
        key="Agenda"
        isMobile = { isMobile }
    />,
    <Playback
        id="Playback"
        key="Playback"
        isMobile = { isMobile }
    />,
    <Hotspot
        id="Hotspot"
        key="Hotspot"
        isMobile = { isMobile }
    />,
    <Support
        id="Support"
        key="Support"
        isMobile = { isMobile }
    />,
    <Contactus
        id="Contactus"
        key="Contactus"
        isMobile = { isMobile }
    />
  ]
  return (
    <main className="main">
        {children}
    </main>
  )
}

export default Home
