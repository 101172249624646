import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
// import CoverImg from '@assets/img/home/introduce/cover.png'
import './index.scss'
// const host = process.env.NODE_ENV === 'development' ? 'http://192.168.10.125:3001/dso-static' : '/dso-static'

function Index ({ id }) {
  return (
      <OverPack
          always={ false }
          id={ id }
          playScale={ 0.4 }
          className={'introduce-container container'}>
          <QueueAnim
              className={'content'}
              type="bottom"
              interval={ 200 }
              duration={ 1000 }
              animConfig={{ opacity: [1, 0] }}
              animatingClassName={['animate__fade-in-up']}
          >
              <h2
                  key={1}
                  className="title"
              >DSS 2023 大会介绍</h2>
              <h2
                  key={2}
                  className="en-title"
              >INTRODUCE</h2>
              <p
                  key={3}
                  className="description"
              >
                  DSS 2023数字供应链安全大会（以下简称“DSS 2023”)将于 8 月 10 日在北京 ·  国家会议中心隆重开幕。本次大会由悬镜安全主办，ISC中国互联网安全大会、中国软件评测中心（工业和信息化部软件与集成电路促进中心）、中国信息通信研究院云计算与大数据研究所、CCF计算机安全专业委员会、北京信息化协会信息技术应用创新工作委员会、OpenChain联合发起，OpenSCA开源社区、XRASP代码疫苗社区共同协办，以”开源的力量”为主题，打造以”数字供应链安全”技术论道、产业变革、创新发展为核心交付价值，立足世界、规模宏大、影响力深远的安全盛会，擘画开源驱动下数字安全发展新蓝图。
              </p>
          </QueueAnim>
      </OverPack>
  )
}

export default Index
