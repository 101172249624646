import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import './index.scss'

function Index ({ id, isMobile }) {
  const steps = [
    {
      title: '',
      time: 'AM',
      children: [{
        time: '08:00-08:30',
        title: '嘉宾签到入场'
      }, {
        time: '08:30-09:00',
        title: '大咖祝福集锦&<br>DSS 2023大会宣传片'
      }]
    },
    {
      title: '主持开场/领导致辞',
      time: 'AM',
      children: [{
        time: '09:00-09:05',
        title: '主持人开场',
        name: '谭晓生',
        job: '北京赛博英杰科技有限公司董事长<br>正奇学院院长'
      }, {
        time: '09:05-09:15',
        title: '嘉宾致辞',
        name: '陈&nbsp&nbsp&nbsp&nbsp钟',
        job: '北京大学计算机学院网络与安全实验室主任'
      }, {
        time: '09:15-09:25',
        title: '嘉宾致辞',
        name: '毛欣然',
        job: '北京信息化协会信息技术应用创新工作委员会秘书长'
      }, {
        time: '09:25-09:35',
        title: '嘉宾致辞',
        name: '辛晓华',
        job: '开放原子开源基金会副秘书长'
      }]
    },
    {
      title: '院士演讲',
      time: 'AM',
      children: [{
        time: '09:35-10:00',
        title: '夯实开源软件供应链安全基础设施',
        name: '倪光南',
        job: '中国工程院院士'
      }]
    },
    {
      title: '出品人洞察',
      time: 'AM',
      children: [{
        time: '10:00-10:35',
        title: '主旨演讲：开源的力量',
        name: '子&nbsp&nbsp&nbsp&nbsp芽',
        job: '悬镜安全创始人兼CEO<br> DSS大会执行主席'
      }]
    },
    {
      title: '重磅成果发布仪式',
      time: 'AM',
      children: [{
        time: '10:35-10:40',
        title: '中国首个数字供应链SBOM格式联合发布<br>中国首个《数字供应链安全白皮书（2023）》联合发布'
      }]
    },
    {
      title: '产业政策宣讲/用户实践',
      time: 'AM',
      children: [{
        time: '10:40-11:00',
        title: '蚂蚁集团软件供应链安全实践',
        name: '程&nbsp&nbsp&nbsp&nbsp岩',
        job: '蚂蚁集团网络安全副总经理'
      }, {
        time: '11:00-11:20',
        title: '证券行业软件供应链安全探索与实践',
        name: '张建军',
        job: '中信建投证券技术部技术总监'
      }, {
        time: '11:20-11:40',
        title: '打造软件安全中心保障数字供应链安全',
        name: '何国锋',
        job: '中国电信研究院安全技术研究所所长'
      }, {
        time: '11:40-12:00',
        title: '数字经济时代软件供应链安全体系建设',
        name: '何宝宏',
        job: '中国信息通信研究院云计算与大数据研究所所长'
      }]
    },
    {
      title: '工作午餐',
      time: 'PM',
      children: [{
        time: '12:00-13:30',
        title: '午间休息&用餐'
      }]
    },
    {
      title: '产业发展研究/开源生态建设',
      time: 'PM',
      children: [{
        time: '13:30-13:35',
        title: '主持人开场',
        name: '谭晓生',
        job: '北京赛博英杰科技有限公司董事长正奇学院院长'
      }, {
        time: '13:35-13:55',
        title: '聚焦软件供应链构筑安全治理体系',
        name: '翟艳芬',
        job: '中国软件评测中心信发事业部主任'
      }, {
        time: '13:55-14:15',
        title: '开源组件治理实践之安全发包',
        name: '汪永辉',
        job: '平安壹钱包信息安全运营负责人'
      }, {
        time: '14:15-14:35',
        title: '开源软件供应链标准化的实践',
        name: '杨丽藴',
        job: '中国电子技术标准化研究院<br>云计算研究室主任'
      }, {
        time: '14:35-14:55',
        title: '软件供应链产业洞察与华为云可实践',
        name: '张锐刚',
        job: '华为云产业战略官<br>华为可信供应链组长'
      }, {
        time: '14:55-15:14',
        title: '移动APP中的开源软件风险',
        name: '秦晓磊',
        job: '中国软件评测中心安全事业部副总经理'
      }, {
        time: '15:15-15:35',
        title: '探索车联网供应链被忽视的数据安全意识应对思考与实践',
        name: '孙&nbsp&nbsp&nbsp&nbsp权',
        job: '某车联网云服务提供商<br>信息安全负责人'
      }, {
        time: '15:35-15:55',
        title: '企业数字供应链开源治理探索',
        name: '项曙明',
        job: '中兴通讯股份有限公司<br>开源合规&安全治理总监'
      }, {
        time: '15:55-16:15',
        title: '《数字供应链安全白皮书》<br>独家解读',
        name: '董&nbsp&nbsp&nbsp&nbsp毅',
        job: '悬镜安全COO'
      }]
    },
    {
      title: '高端圆桌论坛',
      time: 'PM',
      children: [{
        time: '16:15-17:05',
        title: '论坛主题：<br>构建数字供应链安全产业创新发展新生态',
        children: [{
          title: '主持人：',
          children: [{
            name: '谭校长',
            job: '北京赛博英杰科技有限公司董事长正奇学院院长'
          }]
        }, {
          title: '圆桌嘉宾：',
          children: [{
            name: '严&nbsp&nbsp&nbsp&nbsp明',
            job: '中国计算机学会计算机安全专业<br>委员会荣誉主任<br>公安部一所、三所原所长'
          }, {
            name: '陈&nbsp&nbsp&nbsp&nbsp钟',
            job: '北京大学计算机学院<br>网络与安全实验室主任'
          }, {
            name: '红&nbsp&nbsp&nbsp&nbsp薯',
            job: '开源中国CTO'
          }, {
            name: '高&nbsp&nbsp&nbsp&nbsp丹',
            job: '赛迪顾问股份有限公司业务总经理'
          }, {
            name: '谢&nbsp&nbsp&nbsp&nbsp耘',
            job: '东方通首席科学家'
          }, {
            name: '宁&nbsp&nbsp&nbsp&nbsp戈',
            job: '悬镜安全CTO'
          }]
        }]
      }]
    },
    {
      title: '抽奖环节/合影留念',
      time: 'PM',
      children: [{
        time: '17:05-17:10',
        title: '线下抽奖'
      }, {
        time: '17:10-17:30',
        title: '大会合影留念'
      }, {
        time: '18:00-20:30',
        title: '闭门晚宴'
      }]
    }
  ]

  /* 时间：内容 */
  const stepsList = (data) => {
    return (
        <>
          {
            data.children.map((item, index) => {
              return (
                  <div
                      key={ index }
                      className="step-item"
                  >
                    <span className="step-time">{ item.time }</span>
                    <div className={`step-content ${data.time}`}>
                      <h4 className="step-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                      {
                        data.title !== '高端圆桌论坛' && <div className={'name-job'}>
                            { item.name && <p className="step-name" dangerouslySetInnerHTML={{ __html: item.name }}></p> }
                            { item.job && <p className="step-job" dangerouslySetInnerHTML={{ __html: item.job }}></p> }
                          </div>
                      }
                      {
                          data.title === '高端圆桌论坛' && forumContent(item.children)
                      }
                    </div>
                  </div>
              )
            })
          }
        </>
    )
  }

  /* 论坛内容 */
  const forumContent = (list) => {
    return (
        <div>
          {
            list.map((item, index) => {
              return (
                  <>
                    <div className={'forum-title'} key={index}>
                      {item.title}
                    </div>
                    <div className={'forum-content'}>
                      {
                        item.children.map((ite, ind) => {
                          return (
                              <div key={ind} className={'name-job'}>
                                { ite.name && <p className="step-name" dangerouslySetInnerHTML={{ __html: ite.name }}></p> }
                                { ite.job && <p className="step-job" dangerouslySetInnerHTML={{ __html: ite.job }}></p> }
                              </div>
                          )
                        })
                      }
                    </div>
                  </>
              )
            })
          }
        </div>
    )
  }

  return (
    <OverPack
        always={ false }
        id={ id }
        className="agenda-container container"
        playScale={ 0.5 }
    >
      <QueueAnim
          className="content"
          type="bottom"
          interval={ 200 }
          duration={ 1000 }
          animConfig={{ opacity: [1, 0] }}
          animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title">DSS 2023 大会议程</h2>
        <h2 key={2} className="en-title">Conference Agenda</h2>
        <div key={3} className={'agenda-title'}>
          <span>
            August 10. 2023
          </span>
          <span>
            北京 · 国家会议中心
          </span>
        </div>
        <div key={4} className={'agenda-content'}>
          <QueueAnim
              className="steps-list"
              type="bottom"
              interval={ 200 }
              duration={ 1000 }
              animConfig={{ opacity: [1, 0] }}
              animatingClassName={['animate__fade-in-up']}
          >
            {
              steps.map((item, index) => {
                return (
                    <>
                      <div key={index}>
                        {item.title && <div className={`steps-list-title ${item.time}`}>{item.title}</div>}
                      </div>
                      {
                        stepsList(item)
                      }
                    </>
                )
              })
            }
          </QueueAnim>
        </div>

      </QueueAnim>
    </OverPack>
  )
}

export default Index
