import React, { useRef, useState } from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import CoverImg from '@assets/img/home/Playback/1.png'
import './index.scss'
const host = process.env.NODE_ENV === 'development' ? 'http://192.168.10.159:8889' : '/dss-static'

function Index ({ id, isMobile }) {
  const list = [
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/陈钟_嗨格式压缩副本.mp4',
      title: '嘉宾致辞',
      name: '陈&nbsp&nbsp&nbsp&nbsp钟',
      position: '北京大学计算机学院网络与安全实验室主任',
      playback: 'https://mp.weixin.qq.com/s/OfX6NZ70YGc5mgNXlXfPvA'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/毛新然.mp4',
      title: '嘉宾致辞',
      name: '毛新然',
      position: '北京信息化协会信息技术应用创新工作委员会秘书长'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/辛晓华.mp4',
      title: '嘉宾致辞',
      name: '辛晓华',
      position: '开放原子开源基金会副秘书长',
      playback: 'https://mp.weixin.qq.com/s/Z7-I5BGrGCgnc3nQij4jDQ'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/倪光南_嗨格式压缩副本.mp4',
      title: '夯实开源软件供应链安全基础设施',
      name: '倪光南',
      position: '中国工程院院士',
      playback: 'https://mp.weixin.qq.com/s/Hj-Gnsdpsw0lOPUg5YqnKw'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/子芽_嗨格式压缩副本.mp4',
      ppt: require('@assets/PPT/ziya.pdf'),
      fileName: '悬镜安全子芽-开源的力量.pdf',
      title: '开源的力量',
      name: '子&nbsp&nbsp&nbsp&nbsp芽',
      position: '悬镜安全创始人兼CEO',
      playback: 'https://mp.weixin.qq.com/s/vvmFePGEZN87QMQW5_1YTw'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/程岩_嗨格式压缩副本.mp4',
      ppt: require('@assets/PPT/mayi.pdf'),
      fileName: '蚂蚁集团软件供应链安全实践（公开版）.pdf',
      title: '蚂蚁集团软件供应链安全实践',
      name: '程&nbsp&nbsp&nbsp&nbsp岩',
      position: '蚂蚁集团网络安全副总经理',
      playback: 'https://mp.weixin.qq.com/s/XZnz9xQpkYavQXLipRxpsA'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/张建军_嗨格式压缩副本.mp4',
      ppt: require('@assets/PPT/zhangjianjun.pdf'),
      fileName: '中信建投张建军-证券行业软件供应链安全探索与实践.pdf',
      title: '证券行业软件供应链安全探索与实践',
      name: '张建军',
      position: '中信建投证券技术部技术总监',
      playback: 'https://mp.weixin.qq.com/s/1oQf1PxMCYvo7uW1cU2Rfw'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/何国锋.mp4',
      ppt: require('@assets/PPT/heguofeng.pdf'),
      fileName: '中国电信研究院何国锋-构建软件安全中心，保障供应链安全.pdf',
      title: '打造软件安全中心，保障数字供应链安全',
      name: '何国锋',
      position: '中国电信研究院安全技术研究所所长',
      playback: 'https://mp.weixin.qq.com/s/YxlhVFn8Sboi6C647sUyyA'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/何宝宏.mp4',
      title: '数字经济时代软件供应链安全体系建设',
      name: '何宝宏',
      position: '中国信息通信研究院云计算与大数据研究所所长'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/翟艳芬_嗨格式压缩副本.mp4',
      title: '聚焦软件供应链构筑安全治理体系',
      name: '翟艳芬',
      position: '博中国软件评测中心信发事业部主任',
      playback: 'https://mp.weixin.qq.com/s/CdJmsCM8Q4oX5AYh8W1i-A'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/汪永辉_嗨格式压缩副本.mp4',
      ppt: require('@assets/PPT/wangyonghui.pdf'),
      fileName: '平安壹钱包汪永辉-开源组件治理实践之安全发包.pdf',
      title: '开源组件治理实践之安全发包',
      name: '汪永辉',
      position: '博中国软件评测中心信发事业部主任',
      playback: 'https://mp.weixin.qq.com/s/I3pAuX9Yjgw2962-S-dxmQ'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/杨丽蕴_嗨格式压缩副本.mp4',
      ppt: require('@assets/PPT/yangliwen.pdf'),
      fileName: '电子四院杨丽蕴-开源软件供应链标准化的实践.pdf',
      title: '开源软件供应链标准化的实践',
      name: '杨丽藴',
      position: '中国电子技术标准化研究院云计算研究室主任',
      playback: 'https://mp.weixin.qq.com/s/UgE5TA0iLdBuADrtDFUuqQ'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/张锐刚_嗨格式压缩副本.mp4',
      title: '软件供应链产业洞察与华为云可实践',
      name: '张锐刚',
      position: '华为云产业战略官、华为可信供应链组长',
      playback: 'https://mp.weixin.qq.com/s/DynLdD-aI2M40UZ4hfQBAw'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/秦晓磊_嗨格式压缩副本.mp4',
      title: '移动APP中的开源软件风险',
      name: '秦晓磊',
      position: '中国软件评测中心安全事业部副总经理'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/孙权_嗨格式压缩副本.mp4',
      title: '探索车联网供应链被忽视的数据安全意识应对思考与实践',
      name: '孙&nbsp&nbsp&nbsp&nbsp权',
      position: '某车联网云服务提供商、信息安全负责人',
      playback: 'https://mp.weixin.qq.com/s/q-sMI-wWvHgW9UIrAr2Mdw'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/项曙明_嗨格式压缩副本.mp4',
      ppt: require('@assets/PPT/xiangshuming.pdf'),
      fileName: '中兴通讯项曙明-企业数字供应链开源治理探索.pdf',
      title: '企业数字供应链开源治理探索',
      name: '项曙明 ',
      position: '中兴通讯股份有限公司、开源合规&安全治理总监',
      playback: 'https://mp.weixin.qq.com/s/-mURoKC9vPcdHS7Pk3znzg'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/董毅_嗨格式压缩副本.mp4',
      title: '《数字供应链安全白皮书》独家解读',
      name: '董&nbsp&nbsp&nbsp&nbsp毅',
      position: '悬镜安全COO'
    },
    {
      cover: require('@assets/img/home/Playback/cover.png'),
      video: host + '/直播回放/嗨格式/圆桌论坛_嗨格式压缩副本.mp4',
      title: '构建数字供应链安全产业创新发展新生态',
      name: '圆桌论坛'
    }
  ]
  const [active, setActive] = useState(list[0])
  const [playing, setPlaying] = useState(false)
  const activeVideo = useRef()

  const select = (item) => {
    setActive(item)
    play()
  }

  const play = async () => {
    const dom = activeVideo.current
    if (!dom) return
    if (dom.readyState !== 4) {
      await new Promise(resolve => {
        dom.oncanplay = resolve
      })
    }
    dom.play()
    setPlaying(true)
  }

  return (
    <OverPack
        always={ false }
        id={ id }
        className="playback-container container"
        playScale={ 0.5}
    >
      <QueueAnim
          className="content"
          type="bottom"
          interval={ 200 }
          duration={ 1000 }
          animConfig={{ opacity: [1, 0] }}
          animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title">DSS 2023直播回放</h2>
        <h2 key={2} className="en-title">LIVE PLAYBACK</h2>
        <div className="media-container">
          <div key={3} className="media">
            <video
              ref={activeVideo}
              src={active.video}
              poster={CoverImg}
              controls={playing}
            />
            {
              !playing && <div
                className="mask"
                onClick={ () => play() }
              >
                <i className="play" />
              </div>
            }
          </div>
          <div className="menu-wrapper">
            <ul className="menu">
              {
                list.map((item, index) => {
                  return (
                    <li
                      className={active.video === item.video ? 'active' : ''}
                      key={ index }
                      onClick={() => select(item)}
                    >
                      <h4>{ item.title }</h4>
                      <p style={{ marginBottom: '5px' }} dangerouslySetInnerHTML={{ __html: item.name }}></p>
                      <p dangerouslySetInnerHTML={{ __html: item.position }}></p>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className={active.ppt && active.playback ? 'button-box between' : 'button-box center'} key={4}>
          {
            active.ppt && <a className={'button'} href={active.ppt} download={active.fileName}>嘉宾PPT下载</a>
          }
          {
              active.playback && <a className={'button'} href={active.playback} target={'_blank'} rel="noreferrer">演讲实录回顾</a>
          }
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
