import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import ThemeImg from '@assets/img/home/banner/theme1.png'
import SponsorImg from '@assets/img/home/banner/sponsor.png'
import './index.scss'

function Index ({ id }) {
  return (
    <OverPack
      always={ false }
      id={ id }
      className="banner-container"
      playScale={ 0.3 }
    >
      <QueueAnim
        className="content"
        type="bottom"
        interval={ 200 }
        duration={ 1000 }
        animConfig={{ opacity: [1, 0] }}
        animatingClassName={['animate__fade-in-up']}
      >
        <h1 key={1} className="theme">
          <img src={ThemeImg} alt=""/>
        </h1>
        <div
          className="sponsor"
          key={5}
        >
          <img src={SponsorImg} alt=""/>
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
