import React from 'react'
import './index.scss'
import ziya from '@assets/img/home/Guest/ziya.png'
import chenzhong from '@assets/img/home/Guest/chenzhong.png'
import maoxinran from '@assets/img/home/Guest/maomi.png'
import xinxiaohua from '@assets/img/home/Guest/xinxiaohua.png'
import tanxiaosheng from '@assets/img/home/Guest/tanxiaosheng.png'
import chengyan from '@assets/img/home/Guest/chengyan.png'
import zhangjianjun from '@assets/img/home/Guest/zhangjianjun.png'
import heguofeng from '@assets/img/home/Guest/heguofeng.png'
import hebaohong from '@assets/img/home/Guest/hebaohong.png'
import yanfen from '@assets/img/home/Guest/cuiyanfen.png'
import wangyonghui from '@assets/img/home/Guest/wangyonghui.png'
import yangliwen from '@assets/img/home/Guest/yangliwen.png'
import zhangruigang from '@assets/img/home/Guest/zhangruigang.png'
import qinxiaolei from '@assets/img/home/Guest/qinxiaolei.png'
import sunquan from '@assets/img/home/Guest/sunquan.png'
import xiangshuming from '@assets/img/home/Guest/xiangshuming.png'
import dongyi from '@assets/img/home/Guest/dongyi.png'
import yanming from '@assets/img/home/Guest/yanming.png'
// import chenzhong from '@assets/img/home/Guest/chenzhong.png'
import hongshu from '@assets/img/home/Guest/hongshu.png'
// import businan from '@assets/img/home/Guest/businan.png'
import xieyun from '@assets/img/home/Guest/xieyun.png'
import ningge from '@assets/img/home/Guest/ningge.png'
import gaodan from '@assets/img/home/Guest/gaodan.png'

import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'

function Index ({ id }) {
  const list = [{
    title: 'DSS大会执行主席',
    children: [ziya]
  }, {
    title: 'DSS 大会主持人',
    children: [tanxiaosheng]
  }, {
    title: 'DSS 大会嘉宾致辞',
    children: [chenzhong, maoxinran, xinxiaohua]
  }, {
    title: 'DSS 大会演讲嘉宾',
    children: [hebaohong, yanfen, yangliwen, qinxiaolei, chengyan, zhangruigang, heguofeng, zhangjianjun, wangyonghui, xiangshuming, sunquan, dongyi]
  }, {
    title: 'DSS 大会圆桌论坛嘉宾',
    children: [tanxiaosheng],
    children1: [yanming, chenzhong, hongshu, gaodan, xieyun, ningge]
  }]

  return (
        <OverPack
            always={ false }
            id={ id }
            className="guest-container container"
            playScale={ 0.5 }
        >
            <QueueAnim
                className="content"
                type="bottom"
                interval={ 200 }
                duration={ 1000 }
                animConfig={{ opacity: [1, 0] }}
                animatingClassName={['animate__fade-in-up']}
            >
                <h2
                    key={1}
                    className="title"
                >DSS 2023 大会嘉宾</h2>
                <h2
                    key={2}
                    className="en-title"
                >DSS CONFERENCE GUERST</h2>
                <QueueAnim
                    className="list"
                    type="bottom"
                    interval={ 200 }
                    duration={ 1000 }
                    animConfig={{ opacity: [1, 0] }}
                    animatingClassName={['animate__fade-in-up']}
                >
                    {
                        list.map((item, index) => {
                          return (
                                <div className={'guest-content'} key={index}>
                                    {
                                        item.title && <div className={'guest-content-title'}>
                                            <div className={'iconfont'}>
                                                <span></span>
                                                <span></span>
                                            </div>
                                            { item.title }
                                        </div>
                                    }
                                    <div className={'list'}>
                                        {
                                            item.children.map((ite, ind) => {
                                              return (
                                                    <div key={ind} className={'guest-img'}>
                                                        <img src={ ite } alt=""/>
                                                    </div>
                                              )
                                            })
                                        }
                                    </div>
                                    {
                                        item.children1 && <div className={'list'}>
                                            {
                                                item.children1.map((ite, ind) => {
                                                  return (
                                                        <div key={ind} className={'guest-img'}>
                                                            <img src={ ite } alt=""/>
                                                        </div>
                                                  )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                          )
                        })
                    }
                </QueueAnim>

            </QueueAnim>
        </OverPack>
  )
}

export default Index
