import React from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import zhuban from '@assets/img/home/support/zhuban.png'
import xieban from '@assets/img/home/support/xieban.png'
import './index.scss'

const images = []
for (let i = 1; i < 37; i++) {
  images.push(require(`@assets/img/home/support/${i}.png`))
}

const logoImg = []
for (let i = 1; i < 6; i++) {
  logoImg.push(require(`@assets/img/home/support/logo${i}.png`))
}

function Index ({ id, isMobile }) {
  return (
    <OverPack
        always={ false }
        id={ id }
        className="support-container container"
        playScale={ 0.5 }
    >
      <QueueAnim
          className="content"
          type="bottom"
          interval={ 100 }
          duration={ 1000 }
          animConfig={{ opacity: [1, 0] }}
          animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title">DSS 2023大会支持</h2>
        <h2 key={2} className="en-title">Conference Support</h2>
        <div key={3} className="item">
          <span className="label">主办单位：</span>
          <div className="wrapper one">
            <img src={ zhuban } alt=""/>
          </div>
        </div>
        <div key={4} className="item">
          <span className="label">联合发起单位：</span>
          <div className="wrapper two">
            {
              logoImg.map((item, index) => {
                return (
                    <>
                      <img key={index} src={ item } alt=""/>
                    </>
                )
              })
            }
          </div>
        </div>
        <div key={5} className="item">
          <span className="label">协办单位：</span>
          <div className="wrapper three">
            <img src={ xieban } alt=""/>
          </div>
        </div>
        <div key={6} className="item" style={{ display: 'block', marginBottom: 0 }}>
          <span className="label">合作媒体：</span>
          <QueueAnim
            className="list"
            type="bottom"
            interval={ 50 }
            duration={ 1000 }
            animConfig={{ opacity: [1, 0] }}
            animatingClassName={['animate__fade-in-up']}
          >
            {
              images.map((item, index) => {
                return (
                  <li key={ index }>
                    <img src={ item } alt=""/>
                  </li>
                )
              })
            }
          </QueueAnim>
        </div>
      {/*  <div className={'button-box'} key={7}>
          <div className={'button'}>媒体传播</div>
        </div> */}
      </QueueAnim>
    </OverPack>
  )
}

export default Index
