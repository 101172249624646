import React from 'react'
import QRCode1 from '@assets/img/home/contactus/qrcode-1.png'
import QRCode3 from '@assets/img/home/contactus/qrcode-3.png'
import './index.scss'

function Index ({ id, isMobile }) {
  return (
    <div
      id={ id }
      className="contactus-container container"
    >
      <div className="content">
        <div className="info-wrapper">
          <h2 className="en-title">CONTACT US</h2>
          <h2 className="title">联系我们</h2>
          <p>联系人：小镜</p>
          <p>联系邮箱：gaoxl@anpro-tech.com</p>
        </div>
        <div className="qrcode-container">
          <div className="qrcode-wrapper">
            <img src={ QRCode1 } alt=""/>
            <p>悬镜安全微信公众号</p>
          </div>
          <div className="qrcode-wrapper">
            <img src={ QRCode3 } alt=""/>
            <p>顾问小镜</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
