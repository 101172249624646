import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
// import VideoPlayer from '@components/VideoPlayer'
import letf from '@assets/img/home/videos/left.png'
import right from '@assets/img/home/videos/right.png'
import 'swiper/css'
import './index.scss'

const host = process.env.NODE_ENV === 'development' ? 'http://192.168.10.159:8889' : '/dss-static'

function Index ({ id, isMobile }) {
  const list = [
    {
      cover: require('@assets/img/home/videos/tanxiaosheng.png'),
      video: host + '/大咖祝福/谭晓生.mp4',
      name: '谭晓生',
      job: '赛博英杰创始人兼CEO'
    },
    {
      cover: require('@assets/img/home/videos/maoxinran.png'),
      video: host + '/大咖祝福/毛新然.mp4',
      name: '毛欣然',
      job: '北京信创工委会秘书长'
    },
    {
      cover: require('@assets/img/home/videos/yanfen.png'),
      video: host + '/大咖祝福/翟艳芬.mp4',
      name: '翟艳芬',
      job: '中国软件评测中心信发事业部主任'
    },
    {
      cover: require('@assets/img/home/videos/businan.png'),
      video: host + '/大咖祝福/卜思南.mp4',
      name: '卜思南',
      job: '360数字安全集团副总裁'
    },
    {
      cover: require('@assets/img/home/videos/lishaopeng.png'),
      video: host + '/大咖祝福/李少鹏.mp4',
      name: '李少鹏',
      job: '数世资讯创始人兼CEO'
    },
    {
      cover: require('@assets/img/home/videos/zhangyi.png'),
      video: host + '/大咖祝福/MT.mp4',
      name: 'MT',
      job: '安全419创始人兼CEO'
    },
    {
      cover: require('@assets/img/home/videos/zhangyaojiang.png'),
      video: host + '/大咖祝福/张耀疆.mp4',
      name: '张耀疆',
      job: '安在新媒体创始人兼CEO'
    },
    {
      cover: require('@assets/img/home/videos/duangang.png'),
      video: host + '/大咖祝福/锻钢.mp4',
      name: '段钢',
      job: '看雪学苑创始人'
    },
    {
      cover: require('@assets/img/home/videos/yanxiaotao.png'),
      video: host + '/大咖祝福/tnt.mp4',
      name: '闫小涛（TNT）',
      job: '360漏洞云副总经理'
    },
    {
      cover: require('@assets/img/home/videos/zhangkun.png'),
      video: host + '/大咖祝福/张坤.mp4',
      name: '张坤',
      job: '脑动极光CISO'
    },
    {
      cover: require('@assets/img/home/videos/wangyingjian.png'),
      video: host + '/大咖祝福/呆神.mp4',
      name: '王英建（呆神）',
      job: '未来安全CEO'
    }
  ]
  const [active, setActive] = useState(list[0])
  const [playing, setPlaying] = useState(false)
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const [swiperStatus, setSwiperStatus] = useState({
    isBeginning: true,
    isEnd: false
  })

  const prev = () => {
    controlledSwiper.slidePrev()
    setSwiperStatus((value) => {
      return {
        isBeginning: controlledSwiper.isBeginning,
        isEnd: controlledSwiper.isEnd
      }
    })
  }

  const next = () => {
    controlledSwiper.slideNext()
    setSwiperStatus((value) => {
      return {
        isBeginning: controlledSwiper.isBeginning,
        isEnd: controlledSwiper.isEnd
      }
    })
  }

  const activeVideo = useRef()

  const select = (item) => {
    setActive(item)
    play()
  }

  const play = async () => {
    const dom = activeVideo.current
    if (!dom) return
    if (dom.readyState !== 4) {
      await new Promise(resolve => {
        dom.oncanplay = resolve
      })
    }
    dom.play()
    setPlaying(true)
  }

  return (
      <OverPack
          id={ id }
          always={ false }
          className="videos-container container"
          playScale={ 0.5 }
      >
        {/* <VideoPlayer></VideoPlayer> */}
        <QueueAnim
            className={'content '}
            type="bottom"
            interval={ 200 }
            duration={ 1000 }
            animConfig={{ opacity: [1, 0] }}
            animatingClassName={['animate__fade-in-up']}
        >
          <h2 key={1} className="title">DSS 2023 大咖视频</h2>
          <h2 key={2} className="en-title">BIG BANG VIDEO</h2>
          <div key={3} className={'media'}>
            <video
                ref={activeVideo}
                src={active.video}
                poster={active.cover}
                controls={playing}
            />

            {
                !playing && <div
                    className="mask"
                    onClick={ () => play() }
                >
                  <i className="play" />
                </div>
            }
          </div>

        </QueueAnim>

        <div className="swiper-container">
            <QueueAnim
                className="swiper-container"
                type="bottom"
                interval={ 200 }
                duration={ 1000 }
                animConfig={{ opacity: [1, 0] }}
                animatingClassName={['animate__fade-in-up']}
                component={Swiper}
                spaceBetween={isMobile ? 20 : 50}
                slidesPerView={ isMobile ? 2 : 3}
                onSwiper={setControlledSwiper}
            >
              {
                list.map((item, index) => {
                  return (
                      <SwiperSlide key={ index }>
                        <div
                            key={index}
                            className="slide-wrapper"
                            onClick={() => select(item)}
                        >
                          <div className="mask">
                            <i className="play" />
                          </div>
                          <img src={ item.cover } alt=""/>
                          <div className="info">
                            <h4>{ item.name }</h4>
                            <p>{ item.job }</p>
                          </div>
                        </div>
                      </SwiperSlide>
                  )
                })
              }
            </QueueAnim>

              <div className="page-container">
                  <i
                      className="prev"
                      onClick={() => prev()}
                      style={{
                        opacity: swiperStatus.isBeginning ? 0.3 : 1,
                        cursor: swiperStatus.isBeginning ? 'not-allowed' : 'pointer'
                      }}
                  >
                      <img src={letf} alt=""/>
                  </i>
                  <i
                      className="next"
                      onClick={() => next()}
                      style={{
                        opacity: swiperStatus.isEnd ? 0.3 : 1,
                        cursor: swiperStatus.isEnd ? 'not-allowed' : 'pointer'
                      }}
                  >
                      <img src={right} alt=""/>
                  </i>
              </div>
          </div>

      </OverPack>
  )
}

export default Index
