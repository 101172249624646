import React, { useState, useEffect } from 'react'
import Icon, { UpOutlined } from '@ant-design/icons'
import LogoImg from '@assets/img/home/logo1.png'
import './index.scss'

function Index ({ id, isMobile }) {
  const [visible, setVisible] = useState(false)
  const [active, setActive] = useState('#Home')
  const [isAffix, setIsAffix] = useState(false)

  const previous = (year) => {
    window.open('https://www.dsocon.cn/' + year, '_blank')
  }

  const menus = [
    {
      label: '首页',
      key: '#Banner'
    },
    {
      label: '大会介绍',
      key: '#Introduce'
    },
    // {
    //   label: '大会live',
    //   key: '#Live'
    // },
    {
      label: '大咖视频',
      key: '#Videos'
    },
    {
      label: '大会嘉宾',
      key: '#Guest'
    },
    {
      label: '大会议程',
      key: '#Agenda'
    },
    {
      label: '直播回放',
      key: '#Playback'
    },
    {
      label: '热点资讯',
      key: '#Hotspot'
    },
    {
      label: '大会支持',
      key: '#Support'
    },
    {
      label: [
        <span key={1}>往届回顾
            <Icon component={UpOutlined} />
        </span>,
        <div key={2} className="dropdown-container">
          <div className="dropdown-item" onClick={ () => { previous('2021') } }>DSO 2021</div>
          <div className="dropdown-item" onClick={ () => { previous('') } }>DSO 2022</div>
        </div>
      ],
      // key: '#Retrospect'
      key: '#'
    }
  ]

  const scrollToAnchor = (anchorName) => {
    const state = {
      behavior: 'smooth',
      top: 70
    }
    if (anchorName) {
      const anchorElement = document.getElementById(anchorName.replace('#', ''))
      if (anchorElement) {
        state.top = anchorElement.offsetTop - 70
        window.scrollTo(state)
      }
    }
  }

  /**
   * 点击导航跳转
   * **/
  const handleClick = ({ key }) => {
    if (key === '#') return
    scrollToAnchor(key)
    setVisible(false)
  }

  const initItemScrollTop = () => {
    menus.forEach(item => {
      if (item.key === '#') return
      const dom = document.getElementById(item.key.replace('#', ''))
      if (dom) {
        item.scope = [dom.offsetTop, dom.offsetTop + dom.offsetHeight]
      }
    })
  }

  useEffect(() => {
    initItemScrollTop()
    const scrollScreen = () => {
      let scrollTop = 0
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop
      } else if (document.body) {
        scrollTop = document.body.scrollTop
      }
      setIsAffix(scrollTop > 0)

      const offset = window.innerHeight / 2
      const scroll = scrollTop + offset

      menus.forEach(item => {
        if (item.scope) {
          if (item.scope[0] <= scroll && item.scope[1] >= scroll) {
            setActive(item.key)
          }
        }
      })
    }
    window.addEventListener('scroll', scrollScreen)
    window.addEventListener('resize', initItemScrollTop)
    return () => {
      window.removeEventListener('scroll', scrollScreen)
      window.removeEventListener('resize', initItemScrollTop)
    }
  }, [])

  function Menu () {
    return (
      <ul className="menus">
        {
          menus.map((item, index) => {
            return (
              <li
                className={ item.key === active ? 'active' : ''}
                key={ item.key }
                onClick={ () => handleClick(item) }
              >
                <span>{ item.label }</span>
              </li>
            )
          })
        }
      </ul>
    )
  }

  return (
    <div
      id={ id }
      className={
        `header-container 
        ${isAffix ? 'is-affix' : ''}
        ${isMobile ? 'is-mobile' : ''}
        ${visible ? 'mobile-open' : ''}`
      }>

      <div className="header-wrapper">
        {/* { */}
        {/*  isMobile && ( */}
        {/*    <div */}
        {/*      className="mobile-menu" */}
        {/*      onClick={ */}
        {/*        () => setVisible(!visible) */}
        {/*      } */}
        {/*    > */}
        {/*      <em /> */}
        {/*      <em /> */}
        {/*      <em /> */}
        {/*    </div> */}
        {/*  ) */}
        {/* } */}
        <div
          className="logo-wrapper"
        >
          <img
            src={LogoImg}
            alt="dss大会"
            // width={500} automatically provided
            // height={500} automatically provided
            // blurDataURL="data:..." automatically provided
            // placeholder="blur" // Optional blur-up while loading
          />
        </div>
        <div
          className="menu-wrapper"
        >
          {
            isMobile
              ? (''
                // <Drawer
                //   title={''}
                //   width={ 220 }
                //   placement="left"
                //   className="menu-drawer"
                //   closable={ false }
                //   onClose={ () => setVisible(false) }
                //   open={ visible }
                // >
                //   <Menu
                //     items={ menus }
                //   />
                // </Drawer>
                )
              : (
                <Menu />
                )
          }
        </div>
      </div>
    </div>
  )
}
export default Index
