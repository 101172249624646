import React from 'react'
import '@/style/index.scss'
import Home from './views/home'

function App () {
  return (
      <Home />
  )
}

export default App
