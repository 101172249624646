import React, { useState } from 'react'
import QueueAnim from 'rc-queue-anim'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import './index.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import letf from '@assets/img/home/videos/left.png'
import right from '@assets/img/home/videos/right.png'
import 'swiper/css'

function Index ({ id, isMobile }) {
  const list = [
    {
      href: 'https://mp.weixin.qq.com/s/K-Quj-6x1oWKriZ-ewepBQ',
      name: '悬镜安全：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://mp.weixin.qq.com/s/vvmFePGEZN87QMQW5_1YTw',
      name: '悬镜安全： DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    },
    {
      href: 'https://mp.weixin.qq.com/s/uVX22cGscmIqNvM8kimmGA',
      name: '信息安全与通信保密杂志社：2023数字供应链安全大会邀请函（DSS 2023）'
    },
    {
      href: 'http://app.myzaker.com/news/article.php?pk=64bf8bb78e9f09555b1595e0',
      name: '嘶吼新媒体：重磅来袭 | 2023数字供应链安全大会邀请函（DSS 2023）'
    },
    {
      href: 'https://www.aqniu.com/vendor/98123.html',
      name: '安全牛：重磅来袭 | 2023数字供应链安全大会邀请函（DSS 2023）'
    },
    {
      href: 'https://www.freebuf.com/articles/374112.html',
      name: 'Freebuf： 重磅来袭 | 2023数字供应链安全大会邀请函（DSS 2023）'
    },
    {
      href: 'https://mp.weixin.qq.com/s/EX1NtYQHMEM4tAFNRJIU3A',
      name: '北京信息技术应用创新工作委员会：关注 | 2023数字供应链安全大会邀请函（DSS 2023）'
    },
    {
      href: 'https://www.anquanke.com/post/id/289869',
      name: '安全客：重磅来袭 | 2023数字供应链安全大会邀请函（DSS 2023）'
    },
    {
      href: 'https://mp.weixin.qq.com/s/caYk5dg9edYpbxfyGrm0LQ',
      name: '安全419：悬镜安全子芽：开源群智创新模式是数字供应链发展的力量源泉'
    },
    {
      href: 'https://www.doit.com.cn/p/500424.html',
      name: 'DOIT：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://3g.k.sohu.com/t/n717599083?serialId=5598d58e146e948f0fe8623bd5728ab3&showType=&shp1=NzAzNTk1ODcwNDQ5NTYwMzAyMA%3D%3D&sf_a=weixin',
      name: '搜狐科技：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://mp.weixin.qq.com/s/ubwBOe0xu6o2Gc0-1DZYXg',
      name: '网络安全和信息化：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'http://m.chanyejiawang.com/nd.jsp?mid=380&id=2112&groupId=14',
      name: '产业家：网络安全和信息化：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://www.51cto.com/article/763460.html',
      name: '51CTO：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://www.iyiou.com/news/202308151050261?WxUg5ztDmi=1692098437564',
      name: '亿欧：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://share.gmw.cn/wlaq/2023-08/15/content_36767780.htm',
      name: '光明网：数字供应链安全成业界热点，行业发展须重视“开源力量”'
    }, {
      href: 'http://www.infosecworld.cn/index.php?m=content&c=index&a=show&catid=25&id=4581',
      name: '网信安全世界：DSS 2023数字供应链安全大会成功举办'
    },
    {
      href: 'https://safe.it168.com/a2023/0816/6817/000006817482.shtml',
      name: 'IT168：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://mp.weixin.qq.com/s/xf6VtsPg5bfsXjouhxgf_Q',
      name: '云数智观察：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://www.ccidnet.com/2023/0815/10610241.shtml',
      name: '赛迪网：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://news.mydrivers.com/1/929/929183.htm',
      name: '快科技：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://www.newhua.com/2023/0815/369638.shtml',
      name: '牛华网：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://www.cww.net.cn/article?id=581380',
      name: '通信世界：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://m.huanqiu.com/article/4E8hKSCw4Rn',
      name: '环球网：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://t.m.china.com.cn/convert/c_sDOTNL3k.html',
      name: '中国网：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://www.cctime.com/html/2023-8-15/1660255.htm',
      name: '飞象网：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://mp.weixin.qq.com/s/dTMX6qnS5VjMOLtWATVhnQ',
      name: '互联网安全大会：见证开源力量：DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://zghy.org.cn/item/712089659473055744',
      name: '中国科技新闻网：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://tech.ifeng.com/c/8SH4nQNOPCk',
      name: '凤凰网科技：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://www.zgkjrb.cn/keji/4437043.html',
      name: '中国科技日报：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://zixun.yabxzc.cn/redian/74184.html',
      name: '经济新闻网：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://www.zggskf.com/redian/77650.html',
      name: '中国商业周刊网：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://www.glodnews.com/xinwen/51476.html',
      name: '环球经济报道：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'http://china.snbst.com/zixun/62911.html',
      name: '中国金融观察网：悬镜安全 DSS 2023数字供应链安全大会成功举办'
    }, {
      href: 'https://mp.weixin.qq.com/s/vvmFePGEZN87QMQW5_1YTw',
      name: '悬镜安全：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://www.goupsec.com/news/14227.html',
      name: 'GOUPSEC：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://mp.weixin.qq.com/s/0mQEN6DGMQCdt8rlIBjCyg',
      name: '安全419：DSS2023丨悬镜安全：软件供应链正向数字供应链跃迁式演进'
    }, {
      href: 'https://mp.weixin.qq.com/s/VToEcF2BmA6rr6nrWnsWCQ',
      name: '安在：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://tech.china.com/article/20230828/082023_1398837.html',
      name: '中华网科技：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://science.china.com.cn/2023-08/28/content_42498415.htm',
      name: '中国网科学首发：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://zgtxbdrd.keji.com.cn/zgtxbdrd/202308/25404.html',
      name: '中国通信报道：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://www.zzcll.cn/guonei/117249.html',
      name: '中国经济时报：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'k.sina.com.cn/article_7822017421_1d23a838d00101ad0v.html?from=tech;https://k.sina.cn/article_7822017421_1d23a838d00101ad0v.html?from=tech&kdurlshow=1',
      name: '新浪新闻（科技频道）：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'news.iresaerch.cn/yx/2023/08/854524.shtml',
      name: '艾瑞网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://page.om.qq.com/page/O23GPnsZYKYGIzlx4EXwhTqA0',
      name: '腾讯网科技：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://finance.ifeng.com/c/8SXbpjZvuHo',
      name: '凤凰网财经：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://www.citnews.com.cn/news/202308/165519.html',
      name: 'Citnews中文科技资讯：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://www.pcpop.com/article/6818685.shtml',
      name: '泡泡网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'https://www.xhby.net/sy/cb/202308/t20230825_8062046.shtml',
      name: '新华报业网财经：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://www.kejizaixian.cn/keji/4493776.html',
      name: '科技在线：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://www.aotucq.cn/xinwen/74251.html',
      name: '新华在线网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://xinwen.cjsjw.cn/xinwen/85456.html',
      name: '中国新闻观察网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://dj.cvcai.com/redian/61492.html',
      name: '顶尖财经网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://sy.itns.cn/keji/51239.html',
      name: 'IT商业科技网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }, {
      href: 'http://world.kjcnn.cn/redian/50378.html',
      name: '科技世界网：DSS 2023硬核成果 | 悬镜业内首次定义数字供应链安全'
    }
  ]

  const bannberList = [{
    img: require('@assets/img/home/Hotspot/1.png'),
    url: 'https://www.freebuf.com/articles/374112.html'
  }, {
    img: require('@assets/img/home/Hotspot/2.jpg'),
    url: 'https://tech.ifeng.com/c/8SH4nQNOPCk'
  }, {
    img: require('@assets/img/home/Hotspot/3.png'),
    url: 'http://t.m.china.com.cn/convert/c_sDOTNL3k.html'
  }, {
    img: require('@assets/img/home/Hotspot/4.jpg'),
    url: 'https://mp.weixin.qq.com/s/caYk5dg9edYpbxfyGrm0LQ'
  }]

  const [controlledSwiper, setControlledSwiper] = useState(null)

  const prev = () => {
    controlledSwiper.slidePrev()
  }

  const next = () => {
    controlledSwiper.slideNext()
  }

  return (
    <OverPack
        always={ false }
        id={ id }
        className="hotspot-container container"
        playScale={ 0.5}
    >
      <QueueAnim
          className="content"
          type="bottom"
          interval={ 200 }
          duration={ 1000 }
          animConfig={{ opacity: [1, 0] }}
          animatingClassName={['animate__fade-in-up']}
      >
        <h2 key={1} className="title">DSS 2023大会热点资讯</h2>
        <h2 key={2} className="en-title">HOT NEWES</h2>

        <div className="swiper-container" >
          <Swiper
              key={3}
              modules={[Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              loop
              onSwiper={setControlledSwiper}
          >
            {
              bannberList.map((item, index) => {
                return (
                    <SwiperSlide key={ index }>
                      <a
                          href={item.url}
                          target={'_blank'}
                          key={index}
                          className="slide-wrapper" rel="noreferrer"
                      >
                         <img src={ item.img } alt=""/>
                      </a>
                    </SwiperSlide>
                )
              })
            }
          </Swiper>

          <div className="page-container" key={4}>
            <i
                className="prev"
                onClick={() => prev()}
            >
              <img src={letf} alt=""/>
            </i>
            <i
                className="next"
                onClick={() => next()}
            >
              <img src={right} alt=""/>
            </i>
          </div>
        </div>
        <div className={'list'}>
          <div className={'list-title'}>
            <span></span>
            <p>热点资讯</p>

          </div>
          <ul className={'list-content'}>
            {
              list.map((item, index) => {
                return (
                    <li key={index}>
                      <a href={item.href} target={'_blank'} rel="noreferrer">{item.name}</a>
                    </li>
                )
              })
            }
          </ul>
        </div>
      </QueueAnim>
    </OverPack>
  )
}

export default Index
